export const setAllTraits = (traits) => ({
  type: "SET_ALL_TRAITS_DATA",
  payload: traits,
});

export const setAllSettings = (settings) => ({
  type: "SET_SETTINGS",
  payload: settings,
});
export const setApplicationStats = (stats) => ({
  type: "SET_APPLICATION_STATS",
  payload: stats,
});

export const fetchAllTraitsDescriptions = () => {
  return async (dispatch, getState, { getFirestore }) => {
    const firestore = getFirestore();
    const traitsRef = firestore
      .collection("settings")
      .doc("preferences")
      .collection("dimensionDetails")
      .orderBy("category", "asc");
    try {
      dispatch({
        type: "SET_ALL_TRAITS_DATA_LOADING",
        payload: { loading: true, success: false, error: null },
      });
      traitsRef.onSnapshot((querySnapshot) => {
        var traits = [];
        querySnapshot.forEach((doc) => {
          traits.push({ ...doc.data(), id: doc.id });
        });
        dispatch(setAllTraits(traits));
        dispatch({
          type: "SET_ALL_TRAITS_DATA_LOADING",
          payload: { loading: false, success: true, error: null },
        });
      });
    } catch (err) {
      console.log(err.message);
      dispatch({
        type: "SET_ALL_TRAITS_DATA_LOADING",
        payload: { loading: false, success: false, error: err },
      });
    }
  };
};

export const setScorePreferences =
  (data) =>
  async (dispatch, getState, { getFirestore, getFirebase }) => {
    try {
      dispatch({
        type: "SET_SCORE_PREFERENCES_LOADING",
        payload: { loading: true, success: false, error: null },
      });

      const firebase = getFirebase();
      const setScorePreferences = firebase
        .functions()
        .httpsCallable("setScorePreferences");

      const response = await setScorePreferences(data);
      console.log(response);
      if (response.data.success) {
        dispatch({
          type: "SET_SCORE_PREFERENCES_LOADING",
          payload: { loading: false, success: true, error: null },
        });
        console.log({
          data: response.data,
          message: "Score Preferences changed",
        });
      } else {
        const errorObject = {
          status: 400,
          errorFields: response.data.error,
          errorMessage: response.data.message,
        };
        throw errorObject;
      }
    } catch (err) {
      console.log(err);
      dispatch({
        type: "SET_SCORE_PREFERENCES_LOADING",
        payload: { loading: false, success: false, error: err },
      });
    }
  };

export const updateDescriptions =
  (data) =>
  async (dispatch, getState, { getFirestore, getFirebase }) => {
    try {
      dispatch({
        type: "SET_DIMENSION_DESCRIPTIONS_LOADING",
        payload: { loading: true, success: false, error: null },
      });
      console.log(data);

      const firebase = getFirebase();
      const manageDimensionDescriptionsWeightage = firebase
        .functions()
        .httpsCallable("setDimensionDescriptionsWeightage");

      const response = await manageDimensionDescriptionsWeightage(data);
      console.log(response);
      if (response.data.success) {
        dispatch({
          type: "SET_DIMENSION_DESCRIPTIONS_LOADING",
          payload: { loading: false, success: true, error: null },
        });
        console.log({
          data: response.data,
          message: "Dimension Descriptions changed",
        });
      } else {
        const errorObject = {
          status: 400,
          errorFields: response.data.error,
          errorMessage: response.data.message,
        };
        throw errorObject;
      }
    } catch (err) {
      console.log(err);
      dispatch({
        type: "SET_DIMENSION_DESCRIPTIONS_LOADING",
        payload: { loading: false, success: false, error: err },
      });
    }
  };

export const deleteScoreSetting =
  (data) =>
  async (dispatch, getState, { getFirestore, getFirebase }) => {
    try {
      dispatch({
        type: "SET_SCORE_DELETE_LOADING",
        payload: { loading: true, success: false, error: null },
      });

      const firebase = getFirebase();
      const deleteScoreSetting = firebase
        .functions()
        .httpsCallable("deleteScoreSetting");

      const response = await deleteScoreSetting(data);
      console.log(response);
      if (response.data.success) {
        dispatch({
          type: "SET_SCORE_DELETE_LOADING",
          payload: { loading: false, success: true, error: null },
        });
        console.log({
          data: response.data,
          message: "Score Preferences changed",
        });
      } else {
        const errorObject = {
          status: 400,
          errorFields: response.data.error,
          errorMessage: response.data.message,
        };
        throw errorObject;
      }
    } catch (err) {
      console.log(err);
      dispatch({
        type: "SET_SCORE_DELETE_LOADING",
        payload: { loading: false, success: false, error: err },
      });
    }
  };

export const fetchAllSettings = () => {
  return async (dispatch, getState, { getFirestore }) => {
    const firestore = getFirestore();
    const settingsRef = firestore.collection("settings").doc("preferences");
    const scoreSettingsRef = firestore
      .collection("settings")
      .doc("preferences")
      .collection("scorePreferences");
    try {
      dispatch({
        type: "SET_SETTINGS_LOADING",
        payload: { loading: true, success: false, error: null },
      });
      settingsRef.onSnapshot((querySnapshot) => {
        let settings = querySnapshot.data();
        dispatch(setAllSettings(settings));
      });
      scoreSettingsRef.orderBy("min", "desc").onSnapshot((querySnapshot) => {
        let scorePeferences = [];
        if (querySnapshot.size) {
          querySnapshot.forEach((snapshot) => {
            scorePeferences.push({ id: snapshot.id, ...snapshot.data() });
            if (scorePeferences.length === querySnapshot.size) {
              dispatch({
                type: "SET_SCORE_SETTINGS",
                payload: scorePeferences,
              });
              dispatch({
                type: "SET_SETTINGS_LOADING",
                payload: { loading: false, success: true, error: null },
              });
            }
          });
        } else {
          dispatch({
            type: "SET_SETTINGS_LOADING",
            payload: { loading: false, success: true, error: null },
          });
        }
      });
    } catch (err) {
      console.log(err.message);
      dispatch({
        type: "SET_SETTINGS_LOADING",
        payload: { loading: false, success: false, error: err },
      });
    }
  };
};

export const fetchApplicationStats = () => {
  return async (dispatch, getState, { getFirestore }) => {
    const firestore = getFirestore();
    const applicationStatsRef = firestore
      .collection("applicationStats")
      .doc("counts");

    try {
      dispatch({
        type: "SET_APPLICATION_STATS_LOADING",
        payload: { loading: true, success: false, error: null },
      });
      applicationStatsRef.onSnapshot((querySnapshot) => {
        let counts = querySnapshot.data();

        dispatch(setApplicationStats({ ...counts }));
      });
    } catch (err) {
      console.log(err.message);
      dispatch({
        type: "SET_APPLICATION_STATS_LOADING",
        payload: { loading: false, success: false, error: err },
      });
    }
  };
};

export const updateStatusSettings =
  (data) =>
  async (dispatch, getState, { getFirestore, getFirebase }) => {
    const firestore = getFirestore();
    try {
      const { type, status } = data;
      if (data) {
        if (type === "candidate") {
          await firestore
            .collection("settings")
            .doc("preferences")
            .update({ candidateAns: status });
        } else if (type === "carrier") {
          await firestore
            .collection("settings")
            .doc("preferences")
            .update({ carrierAns: status });
        }
      }
    } catch (err) {
      console.log(err.message);
    }
  };

export const updateRatios =
  (data) =>
  async (dispatch, getState, { getFirestore, getFirebase }) => {
    const firestore = getFirestore();
    try {
      dispatch({
        type: "SET_RATIO_LOADING",
        payload: { loading: true, success: false, error: false },
      });
      console.log(data);

      if (data) {
        const { humanticsRatio, receptivitiRatio } = data;
        await firestore
          .collection("settings")
          .doc("preferences")
          .update({ humanticsRatio, receptivitiRatio });

        const candidateRef = await firestore.collection("candidates").get();

        for (let i in candidateRef.docs) {
          let doc = candidateRef.docs[i];
          doc.ref.update({ awaitingHumanticsResponse: true });
        }
      }

      dispatch({
        type: "SET_RATIO_LOADING",
        payload: { loading: false, success: true, error: false },
      });
    } catch (err) {
      console.log(err.message);
      dispatch({
        type: "SET_RATIO_LOADING",
        payload: { loading: false, success: false, error: err },
      });
    }
  };
