import "./button-primary.styles.scss";
import SmallLoader from "../../atoms/small-Loader/small-Loader";
const ButtonPrimary = ({
  children,
  copyLink,
  icon,
  border,
  mid,
  center,
  loading,
  secondary,
  ...props
}) => {
  return (
    <div className={`button-primary-container ${center ? "text-center" : ""}`}>
      <button
        disabled={loading}
        className={`${border ? "border" : ""} ${
          secondary ? "Secondary-button" : "Primary-button"
        } ${copyLink ? "copy-link" : ""} ${mid ? "mid" : ""}`}
        {...props}
      >
        {loading ? (
          <span className="loading-container">
            {loading ? <SmallLoader borderSize="3px" /> : ""}
            {(icon && !loading) || !icon ? children : ""}
          </span>
        ) : (
          children
        )}
      </button>
    </div>
  );
};

export default ButtonPrimary;
