import { combineReducers } from "redux";

import baseReducer from "./base/base.reducer";
import candidateReducer from "./candidate/candidate.reducer";
import carrierReducer from "./culture-carrier/culture-carriers.reducer";
import organizationReducer from "./organization/organization.reducer";
import settingsReducer from "./settings/settings.reducer";
import questionaireReducer from "./questionaire/questionaire.reducer";

export default combineReducers({
  base: baseReducer,
  organization: organizationReducer,
  settings: settingsReducer,
  candidate: candidateReducer,
  carrier: carrierReducer,
  adminQuestionnaire: questionaireReducer,
});
