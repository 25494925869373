const INITIAL_STATE = {
  currentMesage: "Hello, this is my initial message!",
  lastMessage: "",
  user: {
    email: "",
    displayName: "",
    role: "",
    authLoaded: false,
    authenticated: false,
  },
  allowedUsers: "",
  allUsers: "",
  authLoading: false,
};

const baseReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case "UPDATE_MESSAGE":
      return {
        currentMesage: action.payload,
        lastMessage: state.currentMesage,
      };

    case "CHANGE_USER":
      return {
        ...state,
        user: action.payload,
      };
    case "SET_ALLOWED_USERS":
      return {
        ...state,
        allowedUsers: action.payload,
      };
    case "SET_MANAGE_USERS_DOC":
      return {
        ...state,
        allUsers: action.payload,
      };
    case "AUTH_LOADING":
      return { ...state, authLoading: action.payload };
    default:
      return state;
  }
};

export default baseReducer;

//all the logic of what to update goes here
