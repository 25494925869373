export const setAllCandidates = (candidate) => ({
  type: "SET_ALL_CANDIDATES",
  payload: candidate,
});
export const setCandidatesSearchParams = (candidate) => ({
  type: "SET_CANDIDATES_SEARCH_PARAMS",
  payload: candidate,
});
export const setAwaitingCandidates = (candidate) => ({
  type: "SET_AWAITING_CANDIDATES",
  payload: candidate,
});
export const setCurrentCandidates = (candidate) => ({
  type: "SET_CURRENT_CANDIDATES",
  payload: candidate,
});
export const setLastCandidateDoc = (doc) => ({
  type: "SET_LAST_CANDIDATE_DOC",
  payload: doc,
});

export const resetCurrentCandidatesRedux = () => ({
  type: "RESET_CURRENT_CANDIDATES_REDUX",
});

export const fetchCandidateSearchParams = () => {
  return async (dispatch, getState, { getFirestore }) => {
    const firestore = getFirestore();
    const candidateRef = firestore
      .collection("candidateSearchParams")
      .orderBy("timestamp", "desc");

    try {
      let query = candidateRef;

      query.onSnapshot((querySnapshot) => {
        const candidates = [];
        const awaitingCandidates = [];

        querySnapshot.forEach((doc) => {
          const candidateData = doc.data();

          if (
            candidateData.awaitingHumanticsResponse === false ||
            candidateData.inProgress
          ) {
            candidates.push({
              ...doc.data(),
              id: doc.id,
              awaitingHumanticsResponse: false,
            });
          } else {
            awaitingCandidates.push({
              ...doc.data(),
              id: doc.id,
              awaitingHumanticsResponse: true,
            });
          }
        });

        dispatch(setCandidatesSearchParams(candidates));
        dispatch(setAwaitingCandidates(awaitingCandidates));
      });
    } catch (err) {
      dispatch({
        type: "SET_CANDIDATE_LOADING",
        payload: { loading: false, success: false, error: err },
      });
    }
  };
};

export const fetchCurrentCandidates = (id) => {
  return async (dispatch, getState, { getFirestore }) => {
    const db = getFirestore();
    const { candidatesSearchParams } = getState().candidate;
    if (candidatesSearchParams) {
      const selectedCandidates = candidatesSearchParams.filter(
        (x) =>
          x.organizationsCountOfCandidates &&
          x.organizationsCountOfCandidates.find((org) => org.orgId === id)
      );

      dispatch({
        type: "SET_CURRENT_CANDIDATE_LOADING",
        payload: { loading: true, success: false, error: null },
      });

      try {
        if (!selectedCandidates.length) {
          dispatch(setCurrentCandidates([]));
          dispatch({
            type: "SET_CURRENT_CANDIDATE_LOADING",
            payload: { loading: false, success: true, error: null },
          });
          return;
        }

        const candidateFetchPromises = selectedCandidates.map(async (can) => {
          const snapshot = await db.collection("candidates").doc(can.id).get();
          let candidateData = {
            id: can.id,
            checkedStatus: false, // Default to false indicating the candidate might not be fully processed.
          };

          if (snapshot.exists) {
            candidateData = {
              ...candidateData,
              ...snapshot.data(),
              checkedStatus:
                snapshot.data().awaitingHumanticsResponse === false,
            };
          }
          return candidateData; // Always return candidate data with a status flag.
        });

        let tempCandidates = await Promise.all(candidateFetchPromises);

        dispatch(setCurrentCandidates(tempCandidates));

        dispatch({
          type: "SET_CURRENT_CANDIDATE_LOADING",
          payload: { loading: false, success: true, error: null },
        });
      } catch (err) {
        dispatch({
          type: "SET_CURRENT_CANDIDATE_LOADING",
          payload: { loading: false, success: false, error: err },
        });
        console.log(err.message);
      }
    }
  };
};

export const addCandidateToOrganization = (data, org) => {
  return async (dispatch, getState, { getFirestore }) => {
    dispatch({
      type: "SET_ADD_CANDIDATE_LOADING",
      payload: { loading: true, success: false, error: null },
    });
    const firestore = getFirestore();
    const { candidate, orgId } = data;
    const organizationRef = firestore.collection("organizations").doc(orgId);

    try {
      const candidateRef = firestore.collection("candidates").doc(candidate.id);
      const candidateSearchParamsRef = firestore
        .collection("candidateSearchParams")
        .doc(candidate.id);

      const candidatesAssessedCount = org.candidatesAssessedCount
        ? org.candidatesAssessedCount
        : 0;
      const candidateRes = await candidateRef.get();
      const candidateData = candidateRes.data();

      let organizationsCountOfCandidates =
        candidateData.organizationsCountOfCandidates
          ? candidateData.organizationsCountOfCandidates
          : [];

      await organizationRef.update({
        candidatesAssessedCount: candidatesAssessedCount + 1,
      });
      organizationsCountOfCandidates.push({ orgId, orgName: org.name });
      await candidateRef.update({ organizationsCountOfCandidates });
      await candidateSearchParamsRef.update({ organizationsCountOfCandidates });

      dispatch({
        type: "SET_ADD_CANDIDATE_LOADING",
        payload: { loading: false, success: true, error: null },
      });
    } catch (err) {
      console.log(err.message);
      dispatch({
        type: "SET_ADD_CANDIDATE_LOADING",
        payload: { loading: false, success: false, error: err.message },
      });
    }
  };
};

export const removeCandidate =
  (data) =>
  async (dispatch, getState, { getFirestore, getFirebase }) => {
    try {
      dispatch({
        type: "SET_ADD_CANDIDATE_LOADING",
        payload: { loading: true, success: false, error: null },
      });
      const firestore = getFirestore();
      try {
        const { id, candidateId } = data;
        const orgRef = firestore.collection("organizations").doc(id);
        if (id && candidateId) {
          const candidateRef = await firestore
            .collection("candidates")
            .doc(candidateId)
            .get();
          const candidateData = candidateRef.data();
          const orgDataRef = await orgRef.get();
          const orgData = orgDataRef.data();
          let organizationsCountOfCandidates =
            candidateData.organizationsCountOfCandidates;
          organizationsCountOfCandidates =
            organizationsCountOfCandidates.filter((item) => item.orgId !== id);
          await orgRef.update({
            candidatesAssessedCount: orgData.candidatesAssessedCount - 1,
          });

          await firestore
            .collection("candidates")
            .doc(candidateId)
            .update({ organizationsCountOfCandidates });
          await firestore
            .collection("candidateSearchParams")
            .doc(candidateId)
            .update({ organizationsCountOfCandidates });

          dispatch({
            type: "SET_ADD_CANDIDATE_LOADING",
            payload: { loading: false, success: true, error: null },
          });
        } else {
          const errorObject = {
            status: 400,
            message: "Invalid Inputs",
          };
          throw errorObject;
        }
      } catch (e) {
        console.log("Error", e);
        const errorObject = {
          success: false,
          message: e,
          data: data,
        };
        return errorObject;
      }
    } catch (err) {
      console.log(err.message);
      dispatch({
        type: "SET_ADD_CANDIDATE_LOADING",
        payload: { loading: false, success: false, error: err.message },
      });
    }
  };
