export const numberFormat = /^[0-9]{0,10}$/;

export const mailFormat =
  // eslint-disable-next-line
  /^[a-zA-Z0-9._-]+@[a-zA-Z0-9-]+\.[a-zA-Z0-9-.]+$/;

export const nameFormat =
  /^[\w'\-,.][^0-9_!¡?÷?¿/\\+=@#$%ˆ&*(){}|~<>;:[\]]{1,}$/;
export const passwordFormat = /^[\s\S]{6,}$/;

export const descriptFormat = /^[\s\S]{30,1700}$/;

export const simpleNameFormatCampaign = /^[\s\S]{3,100}$/;
export const simpleNameFormat = /^[\s\S]{1,20000000}$/;

export const allIntegerFormat = /^[0-9]*$/;
export const urlFormat =
  // eslint-disable-next-line
  /^(http:\/\/www\.|https:\/\/www\.|http:\/\/|https:\/\/)?[a-z0-9]+([\-\.]{1}[a-z0-9]+)*\.[a-z]{2,5}(:[0-9]{1,5})?(\/.*)?$/;

export const phoneNumberFormat =
  /^(\+\d{1,2}\s)?\(?\d{3}\)?[\s.-]\d{3}[\s.-]\d{4}$/;

export const businessPhoneExtension = /^.[0-9]{5}$/;

export const stringFormat = /^[\s\S]{1,30}$/;

export const branchNumberFormat = /^.[0-9]{4}$/;

export const institutionNumberFormat = /^.[0-9]{2}$/;

export const accountNumberFormat = /^.[0-9]{3,9}$/;

export const dateFormat =
  // eslint-disable-next-line
  /^(0?[1-9]|1[0-2])[\/](0?[1-9]|[1-2][0-9]|3[01])[\/]\d{4}$/;
export const floatR =
  /^([+-]?(?:[0-9]+(?:\.[0-9]+)?|\.[0-9]+)(?:[eE][+-]?[0-9]+)?)$/;
