import firebase from "firebase/app";

import "firebase/firestore";
import "firebase/auth";
// import { traitsDescripton } from "../utils/descriptiondb-temp";

const firebaseConfig = {
  apiKey: process.env.REACT_APP_FIREBASE_API_KEY,
  authDomain: process.env.REACT_APP_FIREBASE_AUTH_DOMAIN,
  projectId: process.env.REACT_APP_FIREBASE_PROJECT_ID,
  storageBucket: process.env.REACT_APP_FIREBASE_STORAGE_BUCKET,
  messagingSenderId: process.env.REACT_APP_FIREBASE_MESSAGING_SENDER_ID,
  appId: process.env.REACT_APP_FIREBASE_API_ID,
  measurementId: process.env.REACT_APP_FIREBASE_MEASUREMENT_ID,
  
};

require("firebase/functions");

firebase.initializeApp(firebaseConfig);

export const auth = firebase.auth();
export const firestore = firebase.firestore();
export const functions = firebase.functions();
// if (
//   window.location.hostname === "localhost" &&
//   window.location.pathname !== "/login"
// ) {
//   // auth.useEmulator("http://localhost:9099");
//   functions.useEmulator("localhost", 5001);
//   firestore.useEmulator("localhost", 8080);
// }

export const addUserNameEmailOAuth = async (userAuth) => {
  if (!userAuth) return;
  const userRef = firebase.firestore().doc(`users/${userAuth.uid}`);
  try {
    const snapShot = await userRef.get();
    if (!snapShot.exists) {
      await createUserDocumentCloudFunction(userAuth);
      return userRef;
    } else {
      if (snapShot.data().displayName === null) {
        await createUserDocumentCloudFunction(userAuth);
        return userRef;
      }
      return userRef;
    }
  } catch (err) {
    console.log(err.message);
    return userRef;
  }
};

export const createUserDocumentCloudFunction = async (userAuth) => {
  const functions = firebase.functions();
  const addUserNameEmailToFirebase = functions.httpsCallable(
    "addUserNameEmailToFirebase"
  );
  const userNameEmailData = JSON.stringify({
    userName: userAuth.displayName,
    userEmail: userAuth.email,
  });

  try {
    const resp = await addUserNameEmailToFirebase(userNameEmailData);
    if (resp.data.success) {
    } else {
      const errorObj = {
        status: 500,
        message: resp.error,
      };
      throw errorObj;
    }
  } catch (err) {
    console.log(err.message);
  }
};

const provider = new firebase.auth.GoogleAuthProvider();
provider.setCustomParameters({ prompt: "select_account" });

export const signInWithGoogle = () => auth.signInWithPopup(provider);

export default firebase;
export { firebaseConfig };
