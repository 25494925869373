import "./heading.styles.scss";
const Heading = ({ children, colorPrimary, type, center, ...props }) => {
  return (
    <div className={`heading-container ${center ? "text-center" : ""}`}>
      {type === "h1" && (
        <h1 className={colorPrimary ? "color-primary" : ""}>{children}</h1>
      )}
      {type === "h2" && (
        <h2 className={colorPrimary ? "color-primary" : ""}>{children}</h2>
      )}
      {type === "h3" && <h3>{children}</h3>}
      {type === "h4" && <h4>{children}</h4>}
      {type === "h5" && <h5>{children}</h5>}
    </div>
  );
};

export default Heading;
