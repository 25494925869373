import { useState } from "react";
import { useEffect } from "react";
import { useSelector } from "react-redux";
import { descriptFormat, mailFormat } from "../../utils/regex";
import domtoimage from "dom-to-image";
import firebase from "../../firebase/firebase.utils";

import ButtonPrimary from "../button-primary/button-primary.component";
import InputField from "../input-field/input-field.component";
import Subtext from "../sub-text/sub-text.component";

import "./floating-help.styles.scss";
let initialState = {
  email: "",
  description: "",
};
const FloatingHelp = ({ showHelp, setShowHelp, ...props }) => {
  const [error, setError] = useState({
    email: false,
    description: "",
  });
  const [loading, setLoading] = useState(false);
  const [issueData, setIssueData] = useState(initialState);
  const currentState = useSelector((state) => state);
  const user = useSelector((state) => state.base.user);
  useEffect(() => {
    if (user) {
      setIssueData({ email: user.email, description: "" });
    }
  }, [user]);

  const handleChange = (value, name) => {
    let tempError = { ...error };
    if (value !== "") {
      if (name === "email") {
        if (value.match(mailFormat)) {
          tempError = { ...error, [name]: false };
        } else {
          tempError = { ...error, [name]: true };
        }
      }
      if (name === "description") {
        if (value.match(descriptFormat)) {
          tempError = { ...error, [name]: false };
        } else {
          tempError = { ...error, [name]: true };
        }
      }
    } else {
      tempError = { ...error, [name]: true };
    }

    setError({ ...tempError });
    setIssueData({ ...issueData, [name]: value });
  };

  const handleSubmit = async () => {
    setLoading(true);
    let image;
    try {
      domtoimage
        .toPng(document.getElementById("root"))
        .then(async (dataUrl) => {
          image = dataUrl;

          const reportIssue = firebase.functions().httpsCallable("reportIssue");
          const resp = await reportIssue({
            ...issueData,
            image: image,
            state: JSON.stringify(currentState),
          });
          if (resp) {
            setError({ email: true, description: true });
            setIssueData({ email: user.email, description: "" });
            setShowHelp(false);
          }
          setLoading(false);
        });
    } catch (err) {
      console.log(err.message);
    }
  };

  return (
    <div className="button-floating-container">
      <button className="button-floating" {...props}>
        {props.children}
      </button>
      {showHelp ? (
        <div className="help-modal">
          <div className="help-modal-header">
            <Subtext center small>
              Report an Issue
            </Subtext>
          </div>
          <div className="help-modal-body">
            <InputField
              disabled
              value={issueData.email}
              onChange={(e) => handleChange(e.target.value, "email")}
              placeholder="Enter your email"
            />
            <InputField
              error={error.description}
              success={!error.description && error.description !== ""}
              value={issueData.description}
              onChange={(e) => handleChange(e.target.value, "description")}
              textarea
              disabled={loading}
              placeholder="Describe your issue"
            />
            <ButtonPrimary
              disabled={
                error.description || loading || error.description === ""
              }
              center
              mid
              loading={loading}
              onClick={handleSubmit}
            >
              Send
            </ButtonPrimary>
          </div>
        </div>
      ) : (
        ""
      )}
    </div>
  );
};

export default FloatingHelp;
