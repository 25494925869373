const INITIAL_STATE = {
  allOrganizations: [],
  organizationResult: {},
  others: {},
  successFactors: [],
  topTraits: {},
  organizationKeys: [],
  organizationLoading: { loading: false, error: null, success: false },
  allOrganizationLoading: { loading: false, error: null, success: false },
  allTraitsLoading: { loading: false, error: null, success: false },
  factorLoading: { loading: false, error: null, success: false },
  successFactorLoading: { loading: false, error: null, success: false },
  allTraits: [],
};
const organizationReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case "SET_ALL_ORGANIZATIONS":
      return { ...state, allOrganizations: action.payload };
    case "SET_ORGANIZATION_RESULT":
      return { ...state, organizationResult: action.payload };
    case "SET_OTHERS":
      return { ...state, others: action.payload };
    case "SET_ORGANIZATION_KEYS":
      return { ...state, organizationKeys: action.payload };
    case "SET_TOP_AND_SUCCESS_TRAITS_TOGETHER":
      return {
        ...state,
        topTraits: action.payload.tempTopTraits,
        successFactors: action.payload.tempFactors,
      };
    case "SET_ALL_ORGANIZATION_LOADING":
      return { ...state, allOrganizationLoading: action.payload };
    case "SET_ORGANIZATION_LOADING":
      return { ...state, organizationLoading: action.payload };
    case "SET_SUCCESS_FACTOR_LOADING":
      return { ...state, successFactorLoading: action.payload };
    case "SET_FACTOR_LOADING":
      return { ...state, factorLoading: action.payload };
    case "SET_ALL_TRAITS_DATA":
      return { ...state, allTraits: action.payload };
    case "SET_ALL_TRAITS_DATA_LOADING":
      return { ...state, allTraitsLoading: action.payload };
    case "RESET_ORGANIZATION_REDUX_DATA":
      return {
        ...state,
        topTraits: {},
        successFactors: [],
        others: {},
        organizationKeys: [],
        organizationResult: {},
      };
    default:
      return state;
  }
};

export default organizationReducer;
