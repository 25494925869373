import React from "react";

const HandleActionUrls = React.lazy(() =>
  import("./pages/handle-action-urls/handle-action-urls.component")
);

const TraitsDescriptions = React.lazy(() =>
  import("./pages/trait-descriptions/trait-descriptions.component")
);

const Dashboard = React.lazy(() =>
  import("./pages/dashboard/dashboard.component")
);

const OrganizationDetail = React.lazy(() =>
  import("./pages/organization-detail/organization-detail.component")
);

// const OrganizationRestore = React.lazy(() =>
//   import("./pages/organization-restore/organization-restore.component")
// );

const UserResubmission = React.lazy(() =>
  import("./pages/user-resubmission/user-resubmission.component")
);
const Usage = React.lazy(() =>
  import("./pages/usage-stats/usage-stats.component")
);

const UpdatePassword = React.lazy(() =>
  import("./pages/update-password/update-password.component")
);

const InitialSurveyEmail = React.lazy(() =>
  import("./pages/initial-survey-email/initial-survey-email.component")
);

const UserQuestionnaire = React.lazy(() =>
  import("./pages/user-questionnaire/user-questionnaire.component")
);

const CultureCarrierPage = React.lazy(() =>
  import("./pages/culture-carrier/culture-carrier.component")
);

const CandidatePage = React.lazy(() =>
  import("./pages/candidate/candidate.component")
);

const CandidateReport = React.lazy(() =>
  import("./pages/candidate-report/candidate-report.component")
);

const Cultures = React.lazy(() =>
  import("./pages/cultures/cultures.component")
);

const Participants = React.lazy(() =>
  import("./pages/participants/participants.component")
);

const ManageUsers = React.lazy(() =>
  import("./pages/manage-users/manage-users.component")
);

const GeneralPreferences = React.lazy(() =>
  import("./pages/general-preferences/general-preferences.component")
);

const ScorePreferences = React.lazy(() =>
  import("./pages/score-preferences/score-preferences.component")
);

const SignIn = React.lazy(() => import("./pages/sign-in/sign-in.component"));

const AdminQuestioneers = React.lazy(() =>
  import("./pages/admin-questionnaires/questionnaire-parent.component")
);

const OldQuestionRecord = React.lazy(() =>
  import(
    "./pages/admin-questionnaires/previous-questions/previous-questions-record.component"
  )
);

export const loggedInRoutes = [
  {
    path: "/dashboard",
    name: "dashboard",
    component: Dashboard,
    exact: true,
  },
  {
    path: "/survey",
    name: "survey",
    exact: true,
    component: InitialSurveyEmail,
  },
  {
    path: "/survey/:name/:id/:culturecheck",
    name: "survey",
    exact: true,
    component: InitialSurveyEmail,
  },

  {
    path: "/survey/:name/:id",
    name: "survey",
    exact: true,
    component: InitialSurveyEmail,
  },
  {
    path: "/participants",
    name: "participants",
    component: Participants,
    exact: true,
  },
  {
    path: "/cultures",
    name: "cultures",
    component: Cultures,
  },

  {
    path: "/organization/:type/:organizationId",
    component: OrganizationDetail,
    name: "organizationdetail",
    exact: true,
  },

  {
    path: "/organization/:organizationId",
    component: OrganizationDetail,
    name: "organizationdetail",
    exact: true,
  },

  {
    path: "/previousQuestions",
    name: "previousQuestions",
    component: OldQuestionRecord,
    exact: true,
  },
  {
    path: "/participant/:candidateId",
    name: "user-questionnaire",
    component: UserQuestionnaire,
    exact: true,
  },
  {
    path: "/participant/:organizationName/:organizationId/:candidateId",
    name: "user-questionnaire",
    component: UserQuestionnaire,
    exact: true,
  },
  {
    path: "/culture-carriers/:organizationName/:organizationId/:randomKey",
    name: "user-questionnaire",
    component: UserQuestionnaire,
    exact: true,
  },
   {
    path: "/culture-carriers/:organizationName/:organizationId/:randomKey/:candidateId",
    name: "user-questionnaire",
    component: UserQuestionnaire,
    exact: true,
  },
  {
    path: "/culture-carrier/:orgId/:id",
    name: "culture-carrier-page",
    component: CultureCarrierPage,
    exact: true,
  },

  {
    path: "/candidates/:id",
    name: "candidate-page",
    component: CandidatePage,
    exact: true,
  },
  {
    path: "/candidates/:orgId/:id",
    name: "candidate-report",
    component: CandidateReport,
    exact: true,
  },

  {
    path: "/trait-descriptions",
    name: "trait-descriptions",
    component: TraitsDescriptions,
    exact: true,
  },
  {
    path: "/update-password",
    name: "update-password",
    component: UpdatePassword,
    exact: true,
  },
];
export const adminRoutes = [
  {
    path: "/settings/usage",
    name: "usage-stats",
    component: Usage,
    exact: true,
  },
  {
    path: "/settings/admin-questionnaire",
    name: "admin-questionnaire",
    component: AdminQuestioneers,
    exact: true,
  },

  {
    path: "/settings/resubmission",
    name: "resubmission",
    component: UserResubmission,
    exact: true,
  },

  {
    path: "/settings/manageusers",
    name: "ManageUsers",
    component: ManageUsers,
    exact: true,
  },
  {
    path: "/settings/preferences",
    name: "GeneralPreferences",
    component: GeneralPreferences,
    exact: true,
  },

  {
    path: "/settings/score-preferences",
    name: "ScorePreferences",
    component: ScorePreferences,
    exact: true,
  },
];

export const loggedOutRoutes = [
  {
    path: "/sign_in_link",
    name: "sign in with link",
    component: HandleActionUrls,
  },
  {
    path: "/survey",
    name: "survey",
    exact: true,
    component: InitialSurveyEmail,
  },
  {
    path: "/login",
    name: "login",
    exact: true,
    component: SignIn,
  },

  {
    path: "/survey/:name/:id/:culturecheck",
    name: "survey",
    exact: true,
    component: InitialSurveyEmail,
  },

  {
    path: "/survey/:name/:id",
    name: "survey",
    exact: true,
    component: InitialSurveyEmail,
  },

  {
    path: "/survey",
    name: "survey",
    exact: true,
    component: InitialSurveyEmail,
  },
  {
    path: "/participant/:candidateId",
    name: "user-questionnaire",
    component: UserQuestionnaire,
    exact: true,
  },
  {
    path: "/participant/:organizationName/:organizationId",
    name: "user-questionnaire",
    component: UserQuestionnaire,
    exact: true,
  },

  {
    path: "/participant/:organizationName/:organizationId/:candidateId",
    name: "user-questionnaire",
    component: UserQuestionnaire,
    exact: true,
  },
  {
    path: "/culture-carriers/:organizationName/:organizationId/:randomKey/:candidateId",
    name: "user-questionnaire",
    component: UserQuestionnaire,
    exact: true,
  },
];
