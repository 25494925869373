const INITIAL_STATE = {
  allCandidates: [],
  candidatesSearchParams: [],
  currentCandidates: [],
  awaitingCandidates: [],
  candidateLoading: { loading: false, error: null, success: false },
  currentCandidateLoading: { loading: false, error: null, success: false },
  addCandidateLoading: { loading: false, error: null, success: false },
  candidateStatusLoading: { loading: false, error: null, success: false },
};
const candidateReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case "SET_ALL_CANDIDATES":
      return {
        ...state,
        allCandidates: [...state.allCandidates, ...action.payload],
      };
    case "SET_CANDIDATES_SEARCH_PARAMS":
      return { ...state, candidatesSearchParams: action.payload };
    case "SET_LAST_CANDIDATE_DOC":
      return { ...state, lastCandidateDoc: action.payload };
    case "SET_AWAITING_CANDIDATES":
      return { ...state, awaitingCandidates: action.payload };
    case "SET_CURRENT_CANDIDATES":
      return { ...state, currentCandidates: action.payload };
    case "SET_CANDIDATE_LOADING":
      return { ...state, candidateLoading: action.payload };
    case "SET_CURRENT_CANDIDATE_LOADING":
      return { ...state, currentCandidateLoading: action.payload };
    case "SET_ADD_CANDIDATE_LOADING":
      return { ...state, addCandidateLoading: action.payload };
    case "SET_CANDIDATE_STATUS_LOADING":
      return { ...state, candidateStatusLoading: action.payload };
    case "RESET_CURRENT_CANDIDATES_REDUX":
      return { ...state, currentCandidates: [] };
    default:
      return state;
  }
};

export default candidateReducer;
