export const setAllOrganizations = (orgs) => ({
  type: "SET_ALL_ORGANIZATIONS",
  payload: orgs,
});
export const setOrganizationResult = (result) => ({
  type: "SET_ORGANIZATION_RESULT",
  payload: result,
});
export const setOthers = (result) => ({
  type: "SET_OTHERS",
  payload: result,
});
export const setOrganizationKeys = (result) => ({
  type: "SET_ORGANIZATION_KEYS",
  payload: result,
});

export const setSuccessFactors = (orgs) => ({
  type: "SET_SUCCESS_FACTORS",
  payload: orgs,
});

export const setTopTraits = (orgs) => ({
  type: "SET_TOP_TRAITS",
  payload: orgs,
});
export const setTopAndSuccessTraits = (data) => ({
  type: "SET_TOP_AND_SUCCESS_TRAITS_TOGETHER",
  payload: data,
});
export const resetOrganizationsRedux = () => ({
  type: "RESET_ORGANIZATION_REDUX_DATA",
});

export const createOrganization =
  (data) =>
  async (dispatch, getState, { getFirestore, getFirebase }) => {
    try {
      dispatch({
        type: "SET_ORGANIZATION_LOADING",
        payload: { loading: true, success: false, error: null },
      });

      const firebase = getFirebase();
      const createNewOrganization = firebase
        .functions()
        .httpsCallable("createOrganization");

      const response = await createNewOrganization(data);
      console.log(response);
      if (response.data.success) {
        dispatch({
          type: "SET_ORGANIZATION_LOADING",
          payload: { loading: false, success: true, error: null },
        });
        console.log({ data: response.data, message: "Organization Created" });
      } else {
        const errorObject = {
          status: 400,
          errorFields: response.data.error,
          errorMessage: response.data.message,
        };
        throw errorObject;
      }
    } catch (err) {
      console.log(err);
      dispatch({
        type: "SET_ORGANIZATION_LOADING",
        payload: { loading: false, success: false, error: err },
      });
    }
  };

export const fetchAllOrganizations = () => {
  return async (dispatch, getState, { getFirestore }) => {
    const firestore = getFirestore();
    const organizationRef = firestore.collection("organizations");
    try {
      dispatch({
        type: "SET_ALL_ORGANIZATION_LOADING",
        payload: { loading: true, success: false, error: null },
      });
      organizationRef
        .orderBy("timestamp", "desc")
        .onSnapshot((querySnapshot) => {
          var organizations = [];
          querySnapshot.forEach((doc) => {
            organizations.push({ ...doc.data(), id: doc.id });
          });
          dispatch(setAllOrganizations(organizations));
          dispatch({
            type: "SET_ALL_ORGANIZATION_LOADING",
            payload: { loading: false, success: true, error: null },
          });
        });
    } catch (err) {
      console.log(err.message);
      dispatch({
        type: "SET_ALL_ORGANIZATION_LOADING",
        payload: { loading: false, success: false, error: err },
      });
    }
  };
};

export const getAverage = (filteredCarriers) => {
  let totalAverage = {};
  let othersAverage = {};
  filteredCarriers.forEach((carrier, i) => {
    if (carrier.aggregatedResponse) {
      const drives = {
        ...carrier.aggregatedResponse.drives,
      };
      const personality = carrier.aggregatedResponse.personality;
      const needs = carrier.aggregatedResponse.needs;
      const values = carrier.aggregatedResponse.values;
      const behavioural_factors =
        carrier.aggregatedResponse.behavioural_factors;
      const disc = carrier.aggregatedResponse.disc;
      const ocean_receptiviti = carrier.aggregatedResponse.ocean_receptiviti;
      const ocean_humantics = carrier.aggregatedResponse.ocean_humantics;
      const ocean_personality = carrier.aggregatedResponse.ocean_personality;

      const attributes = [
        { key: "drives", value: drives },
        { key: "personality", value: personality },
        { key: "needs", value: needs },
        { key: "values", value: values },
        { key: "behavioural_factors", value: behavioural_factors },
        { key: "humantic_disc", value: disc },
        { key: "ocean_receptiviti", value: ocean_receptiviti },
        { key: "ocean_humantics", value: ocean_humantics },
        { key: "ocean_personality", value: ocean_personality },
      ];

      attributes.forEach((att) => {
        for (var key in att.value) {
          if (
            att.key !== "humantic_disc" &&
            att.key !== "ocean_receptiviti" &&
            att.key !== "ocean_humantics"
          ) {
            let tempTotal = totalAverage[key] ? totalAverage[key] : "";
            let previousAverage = tempTotal ? tempTotal.average : 0;
            let maximum = tempTotal
              ? Math.max(tempTotal.maximum, att.value[key])
              : att.value[key];
            let minimum = tempTotal
              ? Math.min(tempTotal.minimum, att.value[key])
              : att.value[key];
            let average = att.value[key] + previousAverage;
            if (i + 1 === filteredCarriers.length) {
              average = tempTotal
                ? average / filteredCarriers.length
                : att.value[key];
            }
            totalAverage = {
              ...totalAverage,
              [key]: {
                ...totalAverage[key],
                average: average,
                attribute: att.key,
                minimum: minimum.toFixed(2),
                maximum: maximum.toFixed(2),
                [carrier.id]: att.value[key].toFixed(2),
              },
            };
          } else {
            if (att.key !== "dos" && att.key !== "donts") {
              let tempTotal = othersAverage[key] ? othersAverage[key] : "";
              let previousAverage = tempTotal ? tempTotal.average : 0;
              let maximum = tempTotal
                ? Math.max(tempTotal.maximum, att.value[key])
                : att.value[key];
              let minimum = tempTotal
                ? Math.min(tempTotal.minimum, att.value[key])
                : att.value[key];
              let average = att.value[key] + previousAverage;
              if (i + 1 === filteredCarriers.length) {
                average = tempTotal
                  ? average / filteredCarriers.length
                  : att.value[key];
              }
              othersAverage = {
                ...othersAverage,
                [key]: {
                  ...othersAverage[key],
                  average: average,
                  attribute: att.key,
                  minimum: minimum.toFixed(2),
                  maximum: maximum.toFixed(2),
                  [carrier.id]: att.value[key].toFixed(2),
                },
              };
            }
          }
        }
      });
    }
  });
  let total = totalAverage;
  let tempKeys = [];
  let others = othersAverage;
  let tempTotal = total;
  let tempOthers = others;

  for (let key in total) {
    let squareSum = 0;
    filteredCarriers.forEach((carrier) => {
      let deviation = total[key][carrier.id] - total[key].average;
      let square = deviation * deviation;
      squareSum = squareSum + square;
    });

    squareSum = squareSum / filteredCarriers.length;
    squareSum = Math.sqrt(squareSum);
    tempKeys.push({
      key: key,
      value: parseFloat(total[key].average).toFixed(2),
      min: parseFloat(total[key].minimum).toFixed(2),
      max: parseFloat(total[key].maximum).toFixed(2),
      sd: parseFloat(squareSum.toFixed(2)).toFixed(2),
    });

    tempTotal = {
      ...tempTotal,
      [key]: {
        ...tempTotal[key],
        standardDeviation: squareSum.toFixed(2),
      },
    };
  }
  for (let key in others) {
    let squareSum = 0;
    filteredCarriers.forEach((carrier) => {
      let deviation = others[key][carrier.id] - others[key].average;
      let square = deviation * deviation;
      squareSum = squareSum + square;
    });

    squareSum = squareSum / filteredCarriers.length;
    squareSum = Math.sqrt(squareSum);
    tempOthers = {
      ...tempOthers,
      [key]: {
        ...tempOthers[key],
        standardDeviation: squareSum.toFixed(2),
      },
    };
  }

  return {
    total: tempTotal,
    keys: tempKeys,
    others: tempOthers,
  };
};

export const organizationResults = () => {
  return async (dispatch, getState, { getFirestore }) => {
    try {
      const allCarriers = getState().carrier.allCarriers;
      let filteredCarriers = allCarriers;

      let { total, others, keys } = getAverage(filteredCarriers);
      dispatch(setOrganizationResult(total));
      dispatch(setOrganizationKeys(keys));
      dispatch(setOthers(others));
    } catch (err) {
      console.log(err.message);
    }
  };
};

export const editOrganizationName = (data) => {
  return async (dispatch, getState, { getFirestore }) => {
    const firestore = getFirestore();
    const { name, id } = data;
    let organizationRef = firestore.collection("organizations");
    try {
      await organizationRef.doc(id).update({
        name: name,
      });
    } catch (err) {
      console.log(err.message);
    }
  };
};

export const changeStatusSuccessFactors = (data) => {
  return async (dispatch, getState, { getFirestore }) => {
    const firestore = getFirestore();
    const { id, value, factor } = data;

    let organizationRef = firestore.collection("organizations").doc(id);

    try {
      dispatch({
        type: "SET_FACTOR_LOADING",
        payload: { loading: true, success: false, error: null },
      });
      if (value) {
        await organizationRef.update({
          successFactorCount: value,
        });
      }
      if (factor) {
        const orgDoc = await organizationRef.get();
        const orgData = orgDoc.data();
        let { successFactors, topTraits } = orgData;
        const index = factor.index;
        successFactors[index] = {
          ...successFactors[index],
          enabled: factor.enabled,
        };

        topTraits[factor.key] = {
          ...topTraits[factor.key],
          enabled: factor.enabled,
        };

        await organizationRef.update({
          topTraits,
          successFactors,
        });
      }

      dispatch({
        type: "SET_FACTOR_LOADING",
        payload: { loading: false, success: true, error: null },
      });
    } catch (err) {
      console.log(err.message);
      dispatch({
        type: "SET_FACTOR_LOADING",
        payload: { loading: false, success: false, error: err.message },
      });
    }
  };
};
