const INITIAL_STATE = {
  allCarriers: [],
  carrierLoading: { loading: false, error: null, success: false },
  carrierStatusLoading: { loading: false, error: null, success: false },
};
const carrierReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case "SET_ALL_CARRIERS":
      return { ...state, allCarriers: action.payload };
    case "SET_CARRIER_LOADING":
      return { ...state, carrierLoading: action.payload };
    case "SET_CARRIER_STATUS_LOADING":
      return { ...state, carrierStatusLoading: action.payload };
    case "RESET_CULTURE_CARRIERS_REDUX":
      return {
        ...state,
        allCarriers: [],
      };
    default:
      return state;
  }
};

export default carrierReducer;
