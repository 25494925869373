// import { addUserNameEmailOAuth, auth } from "../../firebase/firebase.utils";

export const updateMessage = (message) => ({
  type: "UPDATE_MESSAGE",
  payload: message,
});

export const changeUser = (user) => ({
  type: "CHANGE_USER",
  payload: user,
});

export const authLoading = (user) => ({
  type: "AUTH_LOADING",
  payload: user,
});

export const listenForManageUsers =
  (data) =>
  async (dispatch, getState, { getFirestore, getFirebase }) => {
    const firestore = getFirestore();
    await firestore.collection("users").onSnapshot((querySnapshot) => {
      let allowedUsers = [];
      querySnapshot.forEach((doc) => {
        allowedUsers.push({ ...doc.data(), id: doc.id });
      });
      if (allowedUsers.length === querySnapshot.size) {
        dispatch({
          type: "SET_MANAGE_USERS_DOC",
          payload: allowedUsers,
        });
      }
    });
  };
