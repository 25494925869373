import { useState } from "react";

import FloatingHelp from "../floating-help/floating-help.component";
import help from "../../assets/help.svg";
import cross from "../../assets/cross.svg";

import "./help.styles.scss";
const Help = ({ ...props }) => {
  const [showHelp, setShowHelp] = useState(false);

  return (
    <div className="help-container">
      <FloatingHelp
        showHelp={showHelp}
        setShowHelp={setShowHelp}
        onClick={() => setShowHelp(!showHelp)}
      >
        {showHelp ? (
          <img className="cross-img" src={cross} alt="" />
        ) : (
          <img src={help} alt="" />
        )}
      </FloatingHelp>
    </div>
  );
};

export default Help;
