const INITIAL_STATE = {
  currentQuestionaire: "",
  currentQuestionaireCandidate: "",
  oldQuestionnaire: "",
  oldQuestionnaireCandidate: "",
  questionnaireLoadingCandidate: {
    loading: false,
    error: null,
    success: false,
  },
  questionnaireLoading: {
    loading: false,
    error: null,
    success: false,
  },
};

const questionaireReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case "SET_ADMIN_QUESTIONAIRE":
      return {
        ...state,
        currentQuestionaire: action.payload,
      };
    case "SET_OLD_QUESTIONNAIRE":
      return { ...state, oldQuestionnaire: action.payload };
    case "SET_ADMIN_QUESTIONAIRE_CANDIDATE":
      return { ...state, currentQuestionaireCandidate: action.payload };
    case "SET_OLD_QUESTIONNAIRE_CANDIDATES":
      return { ...state, oldQuestionnaireCandidate: action.payload };
    case "SET_QUESTIONNAIRE_LOADING":
      return { ...state, questionnaireLoading: action.payload };
    case "SET_QUESTIONNAIRE_LOADING_CANDIDATE":
      return { ...state, questionnaireLoadingCandidate: action.payload };
    default:
      return state;
  }
};

export default questionaireReducer;
