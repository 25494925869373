import React, { useRef } from "react";
import useSpeechRecognitionInput from "../../custom-hooks/useSpeechtoText";
import Heading from "../../components/heading/heading.component";
import Subtext from "../sub-text/sub-text.component";
import Microphone from "../../assets/microphone.svg";
import Microphonelisten from "../../assets/microphone-listen.svg";
import "./input-field.styles.scss";

const InputField = ({
  label,
  labelLarge,
  textarea,
  currentfeild,
  speechRecognitionEnabled,
  questionListening,
  setQuestionListening,
  ...props
}) => {

  const inputRef = useRef(null);
  const { handleInputChange } = useSpeechRecognitionInput(
    props,
    currentfeild,
    questionListening,
    setQuestionListening,
    inputRef
  );

  const handleBlur = (event) => {
    if (props.onBlur) props.onBlur(event);
  };

  return (
    <div className="input-field-container">
      {label && (
        <>
          {!labelLarge ? (
            <Heading type="h4">{label}</Heading>
          ) : (
           <div className="pb-3">

<Subtext mid>{label}</Subtext>
           </div>
          )}
        </>
      )}

      {textarea ? (
        <div className="text-area-wrapper">
          <textarea
            ref={inputRef}
            className={`form-control text-area-section ${
              props.borderTopBottom ? "border-top-bottom" : ""
            } ${props.noBorder ? "no-border" : ""} ${
              props.error ? "error" : props.success ? "success" : ""
            }`}
            onBlur={handleBlur}
            rows="6"
            {...props}
          ></textarea>

          {speechRecognitionEnabled && (
            <div className="microphone" onClick={handleInputChange}>
              <img
                src={
                  questionListening[currentfeild].isListening
                    ? Microphonelisten
                    : Microphone
                }
                alt={
                  questionListening[currentfeild].isListening
                    ? "Listening..."
                    : "Start listening..."
                }
              />
            </div>
          )}
        </div>
      ) : (
        <input
          className={`form-control ${
            props.borderBottom ? "border-bottom" : ""
          } ${props.search ? "search-input" : ""} ${
            props.borderTopBottom ? "border-top-bottom" : ""
          } ${props.noBorder ? "no-border" : ""} ${
            props.error ? "error" : props.success ? "success" : ""
          } ${props.mid ? "mid" : ""} ${props.center ? "center" : ""} ${
            props.small ? "small" : ""
          } ${props.borderBottomlight ? "borderBottomlight" : ""}`}
          onBlur={handleBlur}
          autoComplete="off"
          {...props}
        />
      )}
    </div>
  );
};

export default InputField;
