import { useState, useEffect, useCallback } from "react";
import SpeechRecognition, { useSpeechRecognition  }  from "react-speech-recognition";

const useSpeechRecognitionInput = (
  props,
  currentField,
  questionListening,
  setQuestionListening,
  inputRef  // New parameter
) => {
  const { transcript, resetTranscript } = useSpeechRecognition();
  const [isListening, setIsListening] = useState(false);
  const [prevValue, setPrevValue] = useState("");
 
 
  useEffect(() => {
    if (isListening && questionListening[currentField].isListening) {
      SpeechRecognition.startListening({ continuous: true });
    }
  }, [isListening, questionListening, currentField]);
  const { onChange } = props;

  const memoizedOnChange = useCallback(
    (...args) => onChange(...args),
    [onChange]
  );

  useEffect(() => {
    if (
      transcript &&
      isListening &&
      questionListening[currentField].isListening
    ) {
      const newValue = prevValue + " " + transcript;
      if (newValue !== props.value) {
        memoizedOnChange(
          { target: { value: newValue.trim() } },
          props.currentquestion,
          currentField
        );
      }
    }
    // eslint-disable-next-line
  }, [
    transcript,
    isListening,
    questionListening,
    currentField,
    props.currentquestion,
  ]);

  const handleInputChange = async () => {
    const isCurrentFieldListening = questionListening[currentField].isListening;

    const updatedQuestionListening = questionListening.map((item, index) => ({
      ...item,
      isListening: index === currentField ? !isCurrentFieldListening : false,
    }));

    setQuestionListening(updatedQuestionListening);

    // Stop listening on all fields
    if (isListening) {
      setIsListening(false);
      await SpeechRecognition.stopListening();
    }

    // Reset the transcript when switching to another field
    resetTranscript();

    // Check if any field is still listening
    const isAnyFieldListening = updatedQuestionListening.some(
      (item) => item.isListening
    );

    // If no field is listening, set isListening to false
    if (!isAnyFieldListening) {
      setIsListening(false);
    }

    // Start listening on the new field if it was not listening before
    if (!isCurrentFieldListening) {
      setIsListening(true);
      await SpeechRecognition.startListening({ continuous: true });
    }

    // Update prevValue with the current value of the input field
    setPrevValue(props.value);
  };

  // New effect
  useEffect(() => {
    const current = inputRef.current;
  
    const stopListeningOnFocus = async () => {
      if (isListening) {
        setIsListening(false);
        setQuestionListening(prevState => prevState.map((question, index) => {
          if (index === currentField) {
            return { ...question, isListening: false };
          }
          return question;
        }));
        await SpeechRecognition.stopListening();
      }
    };
  
    if (current) {
      current.addEventListener('focus', stopListeningOnFocus);
  
      return () => {
        current.removeEventListener('focus', stopListeningOnFocus);
      };
    }
  }, [isListening, inputRef, currentField,setQuestionListening]);

  return { handleInputChange };
};

export default useSpeechRecognitionInput;