import React from "react";
import { Redirect, Route, Switch, useHistory } from "react-router";
import { loggedInRoutes, loggedOutRoutes, adminRoutes } from "../routes";
import { useSelector } from "react-redux";
import Sidebar from "../components/sidebar/sidebar.component";
import CircleLoading from "react-loadingg/lib/BoxLoading";
const Layout = () => {
  const pathname = useHistory().location.pathname;
  const authenticated = useSelector(
    (state) => state.base.user && state.base.user.authenticated
  );

  const admin = useSelector(
    (state) => state.base.user && state.base.user.role === "admin"
  );
  const authLoaded = useSelector(
    (state) => state.base.user && state.base.user.authLoaded
  );

  const authLoading = useSelector(
    (state) => state.base.user && state.base.authLoading
  );
  if ((!authLoaded && !authenticated) || authLoading) {
    return <CircleLoading />;
  }

  let finalRoutes = [];

  switch (authenticated) {
    case true:
      switch (admin) {
        case true:
          finalRoutes = [...loggedInRoutes, ...adminRoutes];
          break;

        default:
          finalRoutes = [...loggedInRoutes];
          break;
      }

      break;
    default:
      finalRoutes = [...loggedOutRoutes];
      break;
  }
  return (
    <div className="d-flex pt-5">
      {authenticated && !pathname.includes("/dashboard") ? <Sidebar /> : ""}
      <React.Suspense fallback={<CircleLoading color="#5d9edb" />}>
        <Switch>
          {finalRoutes.map(({ component: Component, ...rest }, idx) => {
            return (
              Component && (
                <Route
                  key={idx}
                  path={rest.path}
                  exact={rest.exact}
                  component={Component}
                />
              )
            );
          })}
          {
            {
              true: <Redirect to="/dashboard" />,
              false: <Redirect to="/login" />,
            }[authenticated && authenticated]
          }
        </Switch>
      </React.Suspense>
    </div>
  );
};

export default Layout;
