import { useHistory, useLocation } from "react-router";
import { useSelector } from "react-redux";
import { Navbar } from "react-bootstrap";
import NavbarDropdown from "../navbar-dropdown/navbar-dropdown.component";

import logo from "../../assets/logo.svg";
import edit from "../../assets/edit.svg";
import "./navbar.styles.scss";
import Navitem from "../nav-item/nav-item.component";
import { useState } from "react";
import { useEffect } from "react";

const NavBar = () => {
  const history = useHistory();
  const location = useLocation();
  const user = useSelector((state) => state.base.user);
  const [candidate, setCandidate] = useState(
    location.pathname === "/candidate" ? true : false
  );

  useEffect(() => {
    location.pathname === "/candidate" ||
    location.pathname.includes("/culture-carriers")
      ? setCandidate(true)
      : setCandidate(false);
  }, [location]);
  return (
    <div className="navbar-container">
      {user.authLoaded ? (
        <Navbar collapseOnSelect={true} expand="lg">
          <Navbar.Brand onClick={() => history.push("/")}>
            <img src={logo} className="logo" alt="Egility Intelligence" />
          </Navbar.Brand>

          {user && user.authenticated ? (
            <div className="row mx-auto">
              <Navitem
                center
                eventKey="1"
                current={history.location.pathname === "/dashboard"}
                onClick={() => {
                  history.push("/dashboard");
                }}
              >
                Dashboard
              </Navitem>
              <Navitem
                center
                current={history.location.pathname === "/cultures"}
                eventKey="1"
                onClick={() => history.push("/cultures")}
              >
                Cultures
              </Navitem>
              <Navitem
                center
                current={history.location.pathname === "/participants"}
                eventKey="2"
                onClick={() => history.push("/participants")}
              >
                Participants
              </Navitem>
            </div>
          ) : candidate ? (
            <div className="row mr-auto">
              <Navitem eventKey="1" onClick={() => history.push("/candidate")}>
                <img src={edit} alt="" />
                Cultural Survey
              </Navitem>
            </div>
          ) : (
            ""
          )}
          <div>{user && user.authenticated ? <NavbarDropdown /> : ""}</div>
        </Navbar>
      ) : (
        ""
      )}
    </div>
  );
};

export default NavBar;
