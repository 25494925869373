import "./sub-text.styles.scss";
const Subtext = ({
  children,
  mid,
  bold,
  pointer,
  red,
  xs,
  small,
  large,
  semiBold,
  gray,
  center,
  underline,
  inline,
  blue,
  normalGray,
  title,
  colored,
  disbaled,
  handleClick,
  ...props
}) => {
  return (
    <div
      className={`sub-text-container ${center ? "text-center" : ""} ${
        inline ? "inline" : ""
      }`}
    >
      <span
        onClick={handleClick}
        className={`${small ? "small" : ""}${blue ? "blue" : ""} ${
          red ? "red" : ""
        }${gray ? " gray" : ""} ${underline ? " underline" : ""}${
          normalGray ? "normal-gray" : ""
        }${semiBold ? " semi-bold" : ""}${bold ? "  bold" : ""}${
          xs ? " x-small" : ""
        }${mid ? " mid" : ""}${pointer ? " cursor-pointer" : ""}${
          center ? " text-center" : ""
        } ${large ? " large" : ""} ${colored ? "colored" : ""} ${
          disbaled ? "disbaled" : ""
        }`}
        title={title ? children : null}
        {...props}
      >
        {children}
      </span>
    </div>
  );
};

export default Subtext;
