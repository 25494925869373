import React, { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import { useHistory, useLocation } from "react-router";
import { Nav } from "react-bootstrap";

import settings from "../../assets/settings.svg";
import edit from "../../assets/edit.svg";
import users from "../../assets/users.svg";

import "./sidebar.styles.scss";
import Navitem from "../nav-item/nav-item.component";

const Sidebar = () => {
  const history = useHistory();
  const location = useLocation();
  const [exists, setExists] = useState("initial");
  const organizations = useSelector(
    (state) => state.organization.allOrganizations
  );

  useEffect(() => {
    if (location.pathname.includes("/settings")) setExists(true);
    else setExists(false);
  }, [organizations, location.pathname]);
  return (
    <>
      {exists ? (
        <Nav className="sidebar-container">
          <div className="sidebar-sticky">
            <div className="settings-sidebar-parent">
              <Navitem
                current={history.location.pathname === "/settings/manageusers"}
                onClick={() => history.push("/settings/manageusers")}
              >
                <img className="user-image" src={users} alt="" />
                Manage Users
              </Navitem>
              <Navitem
                current={
                  history.location.pathname === "/settings/admin-questionnaire"
                }
                onClick={() => history.push("/settings/admin-questionnaire")}
              >
                <img src={edit} alt="" />
                Edit Survey
              </Navitem>
              <Navitem
                current={history.location.pathname === "/settings/preferences"}
                onClick={() => history.push("/settings/preferences")}
              >
                <img src={settings} alt="" />
                General Preferences
              </Navitem>
              <Navitem
                current={
                  history.location.pathname === "/settings/score-preferences"
                }
                onClick={() => history.push("/settings/score-preferences")}
              >
                <i className="fas fa-star"></i>Score Preferences
              </Navitem>
              <Navitem
                eventKey="1"
                current={history.location.pathname === "/settings/resubmission"}
                onClick={() => history.push("/settings/resubmission")}
              >
                <i className="fas fa-repeat"></i>Resubmit Assessments
              </Navitem>
              <Navitem
                current={history.location.pathname === "/settings/usage"}
                onClick={() => history.push("/settings/usage")}
              >
                <i className="fas fa-info-circle"></i>Usage Details
              </Navitem>
            </div>
          </div>
        </Nav>
      ) : (
        ""
      )}
    </>
  );
};

export default Sidebar;
