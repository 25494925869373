import { Nav } from "react-bootstrap";
import "./nav-item.styles.scss";
const Navitem = ({ children, current, center, right, ...props }) => {
  return (
    <div className="nav-item-container">
      <Nav.Link
        className={`${current ? "current" : ""} ${
          center ? "justify-content-center" : right ? "justify-content-end" : ""
        }`}
        {...props}
      >
        {children}
      </Nav.Link>
    </div>
  );
};

export default Navitem;
