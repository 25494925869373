import React, { useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { Route, BrowserRouter, useHistory, Switch } from "react-router-dom";

import Layout from "./layout/layout.component";
import Help from "./components/help/help.component";
import Navbar from "./components/navbar/navbar.component";

import { changeUser } from "./redux/base/base.actions";
import { addUserNameEmailOAuth, auth } from "./firebase/firebase.utils";
import { fetchAllOrganizations } from "./redux/organization/organization.action";
import { fetchCandidateSearchParams } from "./redux/candidate/candidate.action";
import {
  fetchAllSettings,
  fetchAllTraitsDescriptions,
  fetchApplicationStats,
} from "./redux/settings/settings.action";

import "./App.scss";
import ScrollToTop from "./components/scroll-to-top/scroll-to-top.component";
const App = () => {
  const user = useSelector((state) => state.base.user);
  const history = useHistory();
  const dispatch = useDispatch();

  useEffect(() => {
    auth.onAuthStateChanged(async (userAuth) => {
      if (userAuth) {
        var user_jwt;
        await auth.currentUser.getIdToken(true).then((user) => {
          user_jwt = user;
        });
        const userRef = await addUserNameEmailOAuth(userAuth);
        userRef.onSnapshot(async (snapShot) => {
          if (snapShot.data()) {
            dispatch(
              changeUser({
                email: userAuth.email,
                displayName: userAuth.displayName,
                token: user_jwt,
                uid: userAuth.uid,
                role: "admin",
                authenticated: true,
                authLoaded: true,
                ...snapShot.data(),
              })
            );
          } else {
            dispatch(
              changeUser({
                email: "",
                displayName: "",
                role: "",
                authLoaded: true,
                authenticated: false,
              })
            );
          }
        });
      } else {
        dispatch(
          changeUser({
            email: "",
            displayName: "",
            authLoaded: true,
            authenticated: false,
          })
        );
      }
    });
  }, [dispatch, history]);

  useEffect(() => {
    if (user.authenticated) {
      dispatch(fetchCandidateSearchParams());
      dispatch(fetchAllOrganizations());
      dispatch(fetchAllTraitsDescriptions());
      dispatch(fetchAllSettings());
      dispatch(fetchApplicationStats());
    }
  }, [user, dispatch]);

  return (
    <div>
      <BrowserRouter>
        <ScrollToTop />
        <Navbar />

        {user.authenticated ? <Help /> : ""}
        <Switch>
          <Route path="/" render={() => <Layout />} />
        </Switch>
      </BrowserRouter>
    </div>
  );
};

export default App;
