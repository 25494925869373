import React, { useEffect, useState, useRef } from "react";
import settings from "../../assets/settings-blue.svg";
import "./navbar-dropdown.styles.scss";
import { auth } from "../../firebase/firebase.utils";
import { useSelector } from "react-redux";
import Subtext from "../sub-text/sub-text.component";
import { useHistory } from "react-router";
import Navitem from "../nav-item/nav-item.component";

const NavbarDropdown = () => {
  const [show, setShow] = useState(false);
  const ref = useRef(null);
  const { user } = useSelector((state) => state.base);
  const history = useHistory();

  const handleClickOutside = (event) => {
    if (event.target.name === "settings") {
      setShow(!show);
    } else if (ref.current && !ref.current.contains(event.target)) {
      setShow(false);
    }
  };

  useEffect(() => {
    document.addEventListener("click", handleClickOutside, true);
    return () => {
      document.removeEventListener("click", handleClickOutside, true);
    };
  });

  return (
    <div className="navbar-dropdown-container">
      <div className="header-image">
        <Navitem name="settings" right>
          <img alt="" src={settings} name="settings" />
          Settings
        </Navitem>
      </div>
      {show && (
        <span ref={ref} className="dropdown">
          {user.displayName ? (
            <Subtext small bold onClick={() => user.displayName}>
              <i className="fas fa-user"></i>
              {user.displayName}
            </Subtext>
          ) : (
            ""
          )}
          <Subtext
            gray
            onClick={() => {
              history.push("/update-password");
              setShow(false);
            }}
            small
          >
            <i className="fas fa-lock"></i>Change Password
          </Subtext>
          {user.role === "admin" ? (
            <Subtext
              gray
              onClick={() => {
                history.push("/settings/manageusers");
                setShow(false);
              }}
              small
            >
              <i className="fas fa-cog"></i>Preferences
            </Subtext>
          ) : (
            ""
          )}
          <Subtext small gray onClick={() => auth.signOut()}>
            <i className="fas fa-sign-out-alt"></i>Logout
          </Subtext>
        </span>
      )}
    </div>
  );
};

export default NavbarDropdown;
