const INITIAL_STATE = {
  settings: {},
  applicationStats: {},
  scoreSettings: [],
  scorePreferenceLoading: { loading: false, error: null, success: false },
  applicationStatsLoading: { loading: false, error: null, success: false },
  scoreDeleteLoading: { loading: false, error: null, success: false },
  settingsLoading: { loading: false, error: null, success: false },
  dimensionDescriptionsLoading: { loading: false, error: null, success: false },
  ratioLoading: { loading: false, error: null, success: false },
};
const settingsReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case "SET_SETTINGS_LOADING":
      return { ...state, settingsLoading: action.payload };
    case "SET_SETTINGS":
      return { ...state, settings: action.payload };
    case "SET_APPLICATION_STATS":
      return { ...state, applicationStats: action.payload };
    case "SET_APPLICATION_STATS_LOADING":
      return { ...state, applicationStatsLoading: action.payload };
    case "SET_SCORE_SETTINGS":
      return { ...state, scoreSettings: action.payload };
    case "SET_SCORE_PREFERENCES_LOADING":
      return { ...state, scorePreferenceLoading: action.payload };
    case "SET_DIMENSION_DESCRIPTIONS_LOADING":
      return { ...state, dimensionDescriptionsLoading: action.payload };
    case "SET_RATIO_LOADING":
      return { ...state, ratioLoading: action.payload };
    case "SET_SCORE_DELETE_LOADING":
      return { ...state, scoreDeleteLoading: action.payload };

    default:
      return state;
  }
};

export default settingsReducer;
